// Librairies
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
// import Carousel from '../components/Carousel'
import Content from '../components/Content'
import HelmetRender from '../layouts/HelmetRender'
import MapFormHome from '../components/MapFormHome'
import NotFound from '../components/NotFound'
// import functions from '../functions/functions.js'


import parse from 'html-react-parser'


export default function Home() {
  const slug = 'home'

  const [datas, setDatas] = useState(null)

  // Controller du fetch
  // this.controller = new window.AbortController()

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("page", {slug: slug})

      if ( data.error ) {
        setIsError(true)
      }
      else {
        setDatas({
          jsonld: data.jsonld,
          content: data.content
        })
      }

      setIsLoading(false)
    }
    asyncFetch()
  }, [routeDatas])

  // componentWillUnmount() {
  //   this._asyncRequest && this.controller.abort()
  // }

  return (
    <main id="site-main">
      <ul className="todolist enter">
        <li>EXCLUDE FOLDER DU SERVICEWORKER</li>
        <li>---</li>
        <li>MapForm getDerivedStateFromProps bug on page</li>
        <li>Améliorer Map en mobile (controls, drag...)</li>
        <li>Réflexion Map / Présentation Producteurs ? Filtres ?</li>
        <li>Activer positionnement en one shot uniquement comme GMap</li>
        <li>Ajouter gestion des filtres produits</li>
        <li>Affichage https://artsandculture.google.com/nearby?hl=de</li>
        <li>Affichage http://projektkartan.svenskakyrkan.se/#/karta</li>
        <li>Comment styling open layers map ? https://www.maptiler.com/maps/#topo//vector/8.2/-1.219/47.224</li>
        <li>---</li>
        <li>Bloquer site quand body.menu-active</li>
        <li>Réfléchir aux miniatures images</li>
        <li>Tester les notifications push</li>
        <li>Regarder : animation svg ? codepen svg travel</li>
        <li>Finaliser préparation import suite à réunion</li>
        <li>---</li>
        <li>Gérer les erreurs de connexion (page inexistante), détecter quand hors ligne</li>
        <li>Tester avec ou sans geolocation</li>
        <li>Créer un module de don ?</li>
        <li>Ajouter Matomo</li>
        <li>Créer les adsenses et les intégrer</li>
      </ul>

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={datas.jsonld} />

              <MapFormHome selectedProduits='' />

              <div className='entry-content enter'>
                {/*
                <ins className="adsbygoogle"
                     data-adtest="on"
                     data-ad-format="fluid"
                     data-ad-layout-key="-gu-3+1f-3d+2z"
                     data-ad-client="ca-pub-8766648172843727"
                     data-ad-slot="9237706329"></ins>
                <script>(adsbygoogle = window.adsbygoogle || []).push({})</script>
                */}

                {datas.content.title &&
                  <h1>{datas.content.title}</h1>
                }

                {datas.content.blocks &&
                  <Content blocks={datas.content.blocks} />
                }
              </div>
            </>
          }
        </>
      }
    </main>
  )
}

// // PropTypes
// Home.propTypes = {
//   state: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       datas: PropTypes.object.isRequired,
//       isLoading: PropTypes.bool.isRequired
//     }).isRequired
//   )
// }
//
// export default Home
