import React, { useState, useEffect, useReducer, useRef } from 'react'
import ProdBloc from './ProdBloc'
import Loader from './Loader'

import * as maptilersdk from '@maptiler/sdk'
import "@maptiler/sdk/dist/maptiler-sdk.css"

import img_marker from '../assets/images/marker.svg'
import img_marker_adresse from '../assets/images/marker-adresse.svg'

export default function MapProducteur(props) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  maptilersdk.config.apiKey = 'A9n3BzMD2T4xPL0KxKCA'

  const [state, setState] = useState({
                              currentMarkers: []
                            })

  useEffect( () => {
    async function asyncFetch() {

      if (map.current) return; // stops map from intializing more than once

      map.current = new maptilersdk.Map({
        container: mapContainer.current,
        style: maptilersdk.MapStyle.STREETS,
        center: ( props[0] && props[0].lat && props[0].lng ? [props[0].lng, props[0].lat] : null ),
        navigationControl: true,
        pitchWithRotate: false,
        attributionControl: false,
        geolocateControl: false,
        terrain: false,
        terrainControl: false,
        terrainExaggeration: 0,
        maptilerLogo: false,
        renderWorldCopies: false,
        zoom: 14
      })

      // if ( props.lat && props.lng ) {
      //   var marker_icon = document.createElement('div');
      //   marker_icon.className = 'user_marker';
      //   marker_icon.style.width = '10px';
      //   marker_icon.style.height = '10px';
      //
      //   new maptilersdk.Marker({
      //         element: marker_icon,
      //         anchor: 'center'
      //       })
      //       .setLngLat([props.lng, props.lat])
      //       .addTo(map.current)
      // }
      return await loadAdressesOnMap(Object.values(props))
    }
    asyncFetch()
  }, [props])


  const togglePopup = (prod) => {
    const marker = prod.marker.togglePopup()
    if ( marker.getPopup().isOpen() ) {
      // Ferme la popup précédemment ouverte
      if ( state.selectedProd ) {
        state.selectedProd.marker.togglePopup()
      }
      map.current.setCenter([prod.lng, prod.lat])
      setState({...state,
                selectedProd: prod
               })
    }
    else {
      setState({...state,
                selectedProd: null
               })
    }
  }

  const loadAdressesOnMap = (adresses) => {
    let currentMarkers = []
    let allPoints = []
    // if ( user_lat && user_lng ) {
    //   allPoints.push(new maptilersdk.LngLat(user_lng, user_lat))
    // }

    if ( adresses.length ) {
      console.log(adresses)
      adresses.map((adresse) => {
        console.log(adresse)
        const location = new maptilersdk.LngLat(adresse.lng, adresse.lat)
        let marker_content = "<strong>"+adresse.name+"</strong>"
        marker_content += (adresse.metier ? adresse.metier : "")
        const popup = new maptilersdk.Popup({
                            anchor: 'top'
                          })
                          .setHTML(marker_content)

        var marker_icon = document.createElement('img');
            marker_icon.className = 'marker';
            marker_icon.src = (adresse.type === 'adresse' ? img_marker_adresse : img_marker)
            marker_icon.style.width = '50px';
            marker_icon.style.height = '50px';

        const marker = new maptilersdk.Marker({
                             element: marker_icon,
                             anchor: 'bottom'
                           })
                          .setLngLat(location)
                          .setPopup(popup)
                          .addTo(map.current)

        adresse.marker = marker
        allPoints.push(location)
        currentMarkers.push(adresse)
      })

      // Positionne la vue sur tous les markers
      if ( allPoints.length > 1 ) {
        const fit = map.current.fitBounds(new maptilersdk.LngLatBounds(allPoints.reverse()),
                              {
                                padding: 100,
                                animate: false,
                                pitch: 0,
                                bearing: 0
                              }
                            )
      }
    }

    // Met à jour l'état du composant après la map
    setState({...state,
              currentMarkers: currentMarkers
             })
  }

  return (
    <>
      <div id="osmap" className="fullGrid enter alignfull">
        <div className="map_container">
          <div ref={mapContainer} id="map" className="map" />
        </div>

        <div className="listprods">
          { (!state.currentMarkers || state.currentMarkers.length < 1) ?
            "Pas d'adresse correspondante."
            :
            state.currentMarkers.map(adresse => {
              const param_obj = {map: map.current, prod: adresse}
              return <ProdBloc
                        key={`prodbloc-${adresse.id}`}
                        active={(state.selectedProd && state.selectedProd.id === adresse.id) ? true : false}
                        onClick={e => togglePopup(adresse)}
                        {...param_obj} />
              }
            )
          }
        </div>
      </div>
    </>
  )
}
