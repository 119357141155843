// Librairies
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
// import functions from '../functions/functions.js'
import parse from 'html-react-parser'


// Composant
export default function Page() {
  // Paramètres d'url
  const { slug1, slug2 } = useParams()
  const slug = (slug2 ? slug2 : slug1)

  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    jsonld: null,
    content: null
  })

  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("page", {slug: slug})

      if ( data.error ) {
        setState({
          ...state,
          isLoading: false,
          isError: true
        })
      }
      else {
        setState({
          ...state,
          isLoading: false,
          jsonld: data.jsonld,
          content: data.content
        })
      }
    }
    asyncFetch()
  }, [slug])

  return (
    <main id="site-main">
      <ul className="todolist">
        <li>Ajout auto d'adsense dans les contenus ?</li>
      </ul>
      {!state.isLoading &&
        <>
          {state.isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={state.jsonld} />
              <Breadcrumbs datas={state.jsonld.breadcrumb} />
              <h1 className="innerContent enter">
                {state.content.title && parse(state.content.title)}
              </h1>
              <div className='entry-content enter'>
                {/* state.content.content && parse(state.content.content, {a: HTMLink}) */}
                {state.content.blocks && <Content blocks={state.content.blocks} />}
              </div>
            </>
          }
        </>
      }
    </main>
  )
}
