import { useState } from 'react'

const useLoadDatas = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  // const [data, setData] = useState(false)

  // const [state, dispatch] = useReducer(dataFetchReducer, {
  //   isLoading: false,
  //   isError: false,
  //   data: initialData
  // })

  const fetchData = async (type, params) => {
    if ( !type ) return

    setIsError(false)
    setIsLoading(true)

    let page = ''
    let slug = ''
    let fetch_url = ''

    console.log(type, params)

    switch (type) {
      // Composants Home - Page (Normale, France, Produits)
      case 'page':
        slug = (params ? params.slug : 'home')
        fetch_url = `post?slug=${slug}`
      break;

      // Composant Recherche
      case 'recherche':
        console.log("recherche")
        console.log(params)
        if ( params ) {
          page = (params.page ? params.page : '')
          slug = type+(page && "-"+page)

          fetch_url = `producteurs/recherche?lat=${params.location.lat}&lng=${params.location.lng}&ray=${params.rayon}&opts=${JSON.stringify(params.options)}&prods=${JSON.stringify(params.produits)}&page=${page}`
        }
        else {
          fetch_url = `producteurs/recherche`
        }
      break;

      // Composant ListProducteurs
      case 'listproducteurs':
        page = (params.page ? params.page : '')
        slug = (params.dep ? params.dep : params.region)+(page && "-"+page)

        fetch_url = `listprods?reg=${params.region}&dep=${(params.dep ? params.dep : '')}&page=${page}`
      break;

      // Composant Producteur
      case 'producteur':
        slug = params.slug
        fetch_url = `prod?slug=${slug}`
      break;

      // Composant ListProduits
      case 'listproduits':
        slug = params.type
        fetch_url = `listproduits?slug=${slug}`
      break;

      // Composant Produit
      case 'produit':
        slug = params.produit
        fetch_url = `produit?slug=${slug}`
      break;

      default:
        return null
    }

    const fetchHeaders = new Headers()
    fetchHeaders.append("Content-Type", "application/json")
    fetchHeaders.append("Access-Control-Allow-Origin", "*")
    // fetchHeaders.append("Access-Control-Allow-Credentials", "true")
    // fetchHeaders.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
    // fetchHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Accept-Language, Content-Language, Authorization")
    // fetchHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Authorization")

    // fetchHeaders.append("Content-Type", "application/json")
    // fetchHeaders.append("Content-Type", "text/plain")
    // fetchHeaders.append("Access-Control-Allow-Origin", "*")
    // fetchHeaders.append("Access-Control-Allow-Credentials", "true")
    // fetchHeaders.append("Access-Control-Allow-Headers", "content-type")
    // fetchHeaders.delete('Accept')
    // fetchHeaders.delete('Content-Type')
    // fetchHeaders.delete('Accept-Language')
    // fetchHeaders.delete('Content-Language')

    return await fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/${fetch_url}`, {
      method: 'GET',
      mode: 'cors',
      headers: fetchHeaders,
      // headers: [
      //   ["Origin", "*"],
      //   ["Content-Type", "application/json"],
      //   ["Content-Type", "text/plain"]
      // ],
      // credentials: "include", // include, *same-origin, omit
      // crossorigin: true,
      cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
      referrer: "client", // no-referrer, *client
      // referrerPolicy: 'no-referrer',
      redirect: "follow", // manual, *follow, error
      // signal: signal
    })
    .then(response => {
      return {
        ok: response.ok,
        api: response.json()
      }
    })
    .then(json_datas => {
      setIsLoading(false)
      return json_datas.api.then(function(result) {
        if ( json_datas.ok ) { // Si succès
          // Gère l'affichage après le chargement
          if ( !page || page === 1 ) {
            window.scrollTo(0, 0) // Scroll to top
            setTimeout( function() {
              document.body.classList.remove("loadin") // Supprime la classe .loadin du body
              if ( slug === 'home' ) {
                document.body.classList.add("home") // Ajoute la classe .home du body
              }
              else {
                document.body.classList.remove("home")
              }
            }, 400)
          }

          return result
        }
        else { // Si erreur
          let errors = []
          if ( result.violations ) {
            result.violations.forEach((violation, i) => {
              errors.push(violation.message)
            })
            return {
              error: true,
              message: "Veuillez ajuster les erreurs suivantes :",
              errors: errors
            }
          }
          else {
            return {
              error: true,
              message: "Le chargement des données a rencontré un problème."
            }
          }
        }
      })
    })
    .catch(error => {
      setIsLoading(false)
      setIsError(true)
      return {
        error: true,
        message: "Erreur d'API."
      }
    })
  }

  // Unmount
  return [fetchData, isLoading, isError]
}

export default useLoadDatas
