// Librairies
import React, { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import Loader from '../components/Loader'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import Icon from '../components/Icon'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
export default function ListProducteurs() {

  // Paramètres d'url
  const { region, departement } = useParams()
  const slug = region+(departement ? '-'+departement : '')

  const [page, setPage] = useState(1)
  const [datas, setDatas] = useState(null)

  const [moreProds, setMoreProds] = useState([])
  const [moreLoading, setMoreLoading] = useState(false)
  const nb_pages = 1

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  // Chargement des données initiales
  useEffect( () => {
    async function asyncFetch() {
      console.log(region, departement)
      const data = await fetchDatas("listproducteurs", {region: region, dep: departement, page: 1})

      if ( data.error ) {
        setIsError(true)
      }
      else {
        setDatas({
          jsonld: data.jsonld,
          content: data.content
        })
        setPage(data.content.page)
      }

      setIsLoading(false)
    }
    asyncFetch()
  }, [routeDatas])


  // Chargement de données supplémentaires (pagination)
  useEffect( () => {
    if ( !moreLoading ) return

    async function asyncMoreFetch() {
      console.log(region, departement)
      const data = await fetchDatas("listproducteurs", {region: region, dep: departement, page: page+1})

      if ( data ) {
        let new_datas = datas
        new_datas.content.prods = datas.content.prods.concat(data.content.prods)
        setDatas({...new_datas})
        setPage(data.content.page)
      }
      setMoreLoading(false)
    }
    asyncMoreFetch()
  }, [moreLoading])

  return (
    <main id="site-main">
      <ul className="todolist enter">
        <li>{`Page uniquement d'atterrissage`}</li>
        <li>Ajouter des google adsenses</li>
        <li>Chargement infini au clic</li>
      </ul>

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <Breadcrumbs datas={datas.jsonld.breadcrumb} />
              <h1 className='enter'>
                {datas.content.title && parse(datas.content.title, {a: HTMLink})}
              </h1>
              <div className={`entry-content enter ${moreLoading ? 'loadin' : ''}`}>
                <div className="intro">
                  {datas.content.content && parse(datas.content.content, {a: HTMLink})}
                  {datas.content.blocks && <Content blocks={datas.content.blocks} />}
                </div>

                {datas.content.depts &&
                  <ul>
                    {datas.content.depts.map( (dept) =>
                      <li key={`dept-${dept.id}`}>
                        <NavLink to={dept.url}>{dept.name} ({dept.count})</NavLink>
                      </li>
                    )}
                  </ul>
                }

                <div className="listprods">
                  {datas.content.prods
                    ?
                    <>
                      <div className="fullGrid">
                        {datas.content.prods.map( (prod) =>
                          <div key={`prod-${prod.id}`} className={`prodbloc prodgrid ${prod.type}`}>
                            {prod.logo &&
                              <img className="logo" src={prod.logo.url} width={prod.logo.w} height={prod.logo.h} alt={`Logo de ${prod.title}`} />
                            }
                            <div className="prodcontent">
                              <div className="prodhead">
                                <div className="title">
                                  <h3>{prod.title}</h3>
                                  <strong>{prod.metier}</strong>
                                </div>
                              </div>
                              {prod.intro && <p>{prod.intro}</p>}
                              <p>{prod.cp} {prod.ville}</p>
                              {prod.url &&
                                <NavLink to={`${prod.url}`} title={`Voir ${prod.title}`} className="prodlink">
                                  {(prod.type==="producteur")
                                    ?
                                    <>
                                      <span>Voir la fiche</span>
                                      <Icon icon="fleche-longue" />
                                    </>
                                    :
                                    <>
                                      <span>Voir la fiche</span>
                                      <Icon icon="fleche-longue" />
                                    </>
                                  }
                                </NavLink>
                              }
                            </div>
                          </div>
                        )}
                      </div>

                      {(page && page !== 'last')
                        ?
                        <>
                          <Loader classe={`moreLoader`} />
                          <div className="more">
                            <button id="btn-infinite" className="btn" onClick={() => setMoreLoading(true)}>
                              Charger plus de producteurs
                            </button>
                          </div>
                        </>
                        :
                        <div className="end-message">Vous avez chargé tous les producteurs de votre région :)</div>
                      }
                    </>
                    :
                    <p>Pas de producteurs</p>
                  }
                </div>
              </div>
            </>
          }
        </>
      }
    </main>
  )
}
