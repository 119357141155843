/* eslint no-undef: 0 */ // --> OFF
import React, { useState, useEffect, useReducer, useRef } from 'react'
// https://alligator.io/react/react-autocomplete/
import ProdBloc from './ProdBloc'
import Loader from './Loader'

import * as maptilersdk from '@maptiler/sdk'
import "@maptiler/sdk/dist/maptiler-sdk.css"

import img_marker from '../assets/images/marker.svg'
import img_marker_adresse from '../assets/images/marker-adresse.svg'

export default function Map(props) {
  const mapContainer = useRef(null)
  const map = useRef(null)
  maptilersdk.config.apiKey = 'A9n3BzMD2T4xPL0KxKCA'

  const [state, setState] = useState({
                              mapLoaded: false,
                              map: null,
                              refreshOnMove: false,
                              canRefresh: false,
                              currentMarkers: [],
                              selectedProd: null,
                              popupContent: null,
                              location: {
                                id: (props.id ? props.id : ''),
                                lat: (props.lat ? props.lat : ''), // '46.227638'
                                lng: (props.lng ? props.lng : ''), // '2.213749000000007'
                                nom: (props.nom ? props.nom : '')
                              },
                              rayon: 30,
                              produits: [],
                              selectedProduits: []
                            })

  useEffect( () => {
    async function asyncFetch() {

      if (map.current) return; // stops map from intializing more than once

      map.current = new maptilersdk.Map({
        container: mapContainer.current,
        style: maptilersdk.MapStyle.STREETS,
        center: ( props.lat && props.lng ? [props.lng, props.lat] : null ),
        navigationControl: true,
        pitchWithRotate: false,
        attributionControl: false,
        geolocateControl: false,
        terrain: false,
        terrainControl: false,
        terrainExaggeration: 0,
        maptilerLogo: false,
        renderWorldCopies: false,
        zoom: 14
      })

      if ( props.lat && props.lng ) {
        var marker_icon = document.createElement('div');
        marker_icon.className = 'user_marker';
        marker_icon.style.width = '10px';
        marker_icon.style.height = '10px';

        new maptilersdk.Marker({
              element: marker_icon,
              anchor: 'center'
            })
            .setLngLat([props.lng, props.lat])
            .addTo(map.current)
      }

      if ( props.prods ) {
        return await loadProdsOnMap(props.prods, props.lng, props.lat)
      }
      return;
    }
    asyncFetch()
  }, [props])

  const togglePopup = (prod) => {
    const marker = prod.marker.togglePopup()
    if ( marker.getPopup().isOpen() ) {
      // Ferme la popup précédemment ouverte
      if ( state.selectedProd ) {
        state.selectedProd.marker.togglePopup()
      }
      map.current.setCenter([prod.lng, prod.lat])
      setState({...state,
                selectedProd: prod
               })
    }
    else {
      setState({...state,
                selectedProd: null
               })
    }
  }

  const loadProdsOnMap = (prods, user_lng, user_lat) => {
    console.log("MAP LOAD PRODS")

    const filtres = null

    let currentMarkers = []
    let allPoints = []
    if ( user_lat && user_lng ) {
      allPoints.push(new maptilersdk.LngLat(user_lng, user_lat))
    }

    if ( prods.length ) {
      prods.map((prod) => {
        const location = new maptilersdk.LngLat(prod.lng, prod.lat)
        let marker_content = "<strong>"+prod.name+"</strong>"
        marker_content += (prod.metier ? prod.metier : "")
        const popup = new maptilersdk.Popup({
                            anchor: 'top'
                          })
                          .setHTML(marker_content)

        var marker_icon = document.createElement('img');
            marker_icon.className = 'marker';
            marker_icon.src = (prod.type === 'adresse' ? img_marker_adresse : img_marker)
            marker_icon.style.width = '50px';
            marker_icon.style.height = '50px';

        const marker = new maptilersdk.Marker({
                             element: marker_icon,
                             anchor: 'bottom'
                           })
                          .setLngLat(location)
                          .setPopup(popup)
                          .addTo(map.current)

        prod.marker = marker
        allPoints.push(location)
        currentMarkers.push(prod)
      })

      // Positionne la vue sur tous les markers
      const fit = map.current.fitBounds(new maptilersdk.LngLatBounds(allPoints.reverse()),
                            {
                              padding: 100,
                              animate: false,
                              pitch: 0,
                              bearing: 0
                            }
                          )
    }

    // Met à jour l'état du composant après la map
    setState({...state,
              currentMarkers: currentMarkers
             })
  }

  return (
    <>
      <div id="osmap" className="fullGrid enter alignfull">
        <div className="map_container">
          {state.canRefresh &&
            <button type="button" onClick={refresh} className="btn refresh-map">
              Rechercher dans cette zone...
            </button>
          }
          <div ref={mapContainer} id="map" className="map" />
        </div>

        <div className="listprods">
          { (!state.currentMarkers || state.currentMarkers.length < 1) ?
            "Pas de producteur correspondant."
            :
            state.currentMarkers.map(prod => {
              const param_obj = {map: map.current, prod: prod}
              return <ProdBloc
                        key={`prodbloc-${prod.id}`}
                        active={(state.selectedProd && state.selectedProd.id === prod.id) ? true : false}
                        onClick={e => togglePopup(prod)}
                        {...param_obj} />
              }
            )
          }
        </div>
      </div>
    </>
  )
}
